<template>
<div id="search">
  <div class="search-box">
    <div class="filter-box">
       <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
       <div class="clearAll">
          清空筛选条件
       </div>
    </div>
    <div class="job-list">
      <div class="job-item">
        <div class="job-left">
          <div class="job-title">
            <span>产品经理</span>
            <label>急聘</label>
            <em>6-11k</em>
          </div>
          <div class="job-limit">
            <span>新疆哈密</span>
             <span>本科</span>
              <span>1-3年</span>
          </div>
        </div>
        <div class="job-right">
          <img src="../assets/imgs/icon.jpg">
           <p>
             <span>XX科技</span>
             <label>互联网行业|0-20人</label>
            </p>
        </div>
      </div>
      
    </div>
    <div class="job-re">
      <div class="re-title">
           推荐职位
      </div>
      <div class="re-list">
        <div class="re-item">
          <div class="re-left">
            <img src="../assets/imgs/icon.jpg">
            <p>
              <span>产品经理</span>
              <label>XX科技有限公司</label>
             </p>
          </div>
          <div class="re-right">
            <h3>6-11K</h3>
            <span>哈密-伊州-密河</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
     <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
      }],
      value:'选项1'
    }
  },
  methods: {
   sizeChange(){},
   currentChange(){}
  }
};
</script>

<style lang="scss" scoped>
.search-box{
  width: 1184px;
  min-height:500px ;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
}
.clearAll{
  float: right;
}
.job-list{
  width: 700px;
  float: left;
  .job-item{
    padding: 10px 0;
    height: 80px;
    border-bottom: 1px solid #ccc;
  }
  .job-left{
    float: left;
  }
  .job-title{
    label{
      display: inline-block;
      height: 30px;
      width: 60px;
      text-align: center;
      line-height: 30px;
      border: 1px solid #ccc;
      margin-right: 30px;
      margin-left: 5px;
    }
  }
  .job-limit{
    margin-top: 14px;
    span{
      display: inline-block;
      width: 70px;
      height: 25px;
      background: #ccc;
      text-align: center;
      line-height: 25px;
      margin-right: 10px;
    }
  }
  .job-right{
    float: right;
      margin-top: 20px;
      img{
        width: 50px;
        height: 50px;
        display: block;
        float: left;
      }
      p{
       float: left;
       label{
        display: block;
       }
      }
  }
}
.job-re{
  float: left;
  width: 340px;
  height: 200px;
  padding: 20px;
  background: #ccc;
  margin-left: 20px;
  .re-title{
    margin-bottom: 20px;
  }
  .re-item{
    height: 70px;
    border-bottom: 1px solid #fff;
  }
  .re-left{
    float: left;
    img{
        width: 50px;
        height: 50px;
        display: block;
        float: left;
      }
      p{
       float: left;
       label{
        display: block;
       }
      }
  }
  .re-right{
    float: right;
  }
}
.pagination{
  clear: both;
  text-align: center;
  position: relative;
  top: 30px;
}
</style>

<style lang="scss">
.filter-box{
  .el-select{
    margin-right: 15px;
  }
 .el-input__inner{
  width: 120px;
  height: 30px;
  line-height: 30px;
 }
 .el-input__icon{
   line-height: 30px;
 }
}
</style>
